export const STATUSES = [
  { value: 1, label: 'Hoạt động' },
  { value: 0, label: 'Không hoạt động' },
]
export const PROGRAMME_STATUSES = [
  { value: 0, label: 'Dự thảo' },
  { value: 1, label: 'Đang áp dụng' },
]
export const DIPLOMA_STATUSES = [
  { value: -1, label: 'Từ chối' },
  { value: 0, label: 'Dự thảo' },
  { value: 1, label: 'Đã duyệt' },
  { value: 2, label: 'Đã đóng' },
]
export const TRANSFER_STATUSES = [
  { value: -1, label: 'Từ chối' },
  { value: 0, label: 'Dự thảo' },
  { value: 1, label: 'Đã duyệt' },
]
export const TRANSFER_SUBJECT_STATUSES = [
  { value: 0, label: '(H) Phải học' },
  { value: 1, label: '(M) Miễn học' },
]
export const PROGRAMME_SUBJECT_TYPE = [
  { value: 0, label: 'HP bắt buộc' },
  { value: 1, label: 'HP tự chọn' },
]
export const COUNT_4_GPA_SYMBOLS = [
  { value: 0, label: '&#10060;' },
  { value: 1, label: '&#9989;' },
]
export const STATUS_SYMBOLS = [
  { value: 0, label: '&#10008;' },
  { value: 1, label: '&#10004;' },
]

export const MENU_TYPES = [
  { value: 1, label: 'Quản trị' },
  { value: 2, label: 'Portal' },
]

export const IS_OPTIONAL_SYMBOLS = [
  { value: 0, label: '&#10004;' },
  { value: 1, label: '&#10008;' },
]

export const CREDIT_CLASS_STATUSES = [
  { value: -1, label: 'Hủy lớp' },
  { value: 0, label: 'Dự thảo' },
  { value: 1, label: 'Đang đăng ký' },
  { value: 2, label: 'Đăng ký mở rộng' },
  { value: 3, label: 'Đang học' },
  { value: 4, label: 'Kết thúc' },
]
export const CREDIT_CLASS_TEACHER_STATUSES = [
  { value: 0, label: 'Ngưng dạy' },
  { value: 1, label: 'Đang dạy' },
]
export const CREDIT_CLASS_STUDENT_STATUSES = [
  { value: -2, label: 'Không hoàn thành' },
  { value: -1, label: 'Rút đăng ký' },
  { value: 0, label: 'Đã đăng ký' },
  { value: 1, label: 'Đang học' },
  { value: 2, label: 'Đã hoàn thành' },
]

export const GENDERS = [
  { value: 0, label: 'Không xác định' },
  { value: 1, label: 'Nam' },
  { value: 2, label: 'Nữ' },
]
// Cảm tình đảng
export const HAS_LEARN_PARTY = [
  { value: 0, label: 'Chưa học' },
  { value: 1, label: 'Đã học' },
]

export const COURSE_SEMESTER_STATUSES = [
  { value: 0, label: 'Chưa học' },
  { value: 1, label: 'Đang học' },
  { value: 2, label: 'Đã kết thúc' },
]
export const STUDENT_CLASS_STATUSES = [
  { value: -2, label: 'Đình chỉ' },
  { value: -1, label: 'Thôi học' },
  { value: 0, label: 'Bảo lưu' },
  { value: 1, label: 'Đang học' },
  { value: 2, label: 'Đã hoàn thành chương trình' },
  { value: 3, label: 'Đã tốt nghiệp' },
]

export const ELIGIBLE_STATUSES = [
  { value: 0, label: 'Không' },
  { value: 1, label: 'Có' },
]

export const DISCIPLINE_LEVEL = [
  { value: 1, label: 'Cấp khoa' },
  { value: 2, label: 'Cấp trường' },
  { value: 3, label: 'Cấp bộ' },
  { value: 4, label: 'Cấp nhà nước' },
  { value: 5, label: 'Cấp tỉnh' },
  { value: 6, label: 'Cấp ngành' },
]
export const ROOM_TYPES = [
  { value: 1, label: 'Phòng học lý thuyết' },
  { value: 2, label: 'Phòng máy tính' },
  { value: 3, label: 'Phòng thí nghiệm' },
]

export const YEARS = [
  { value: 1, label: 'Năm thứ nhất' },
  { value: 2, label: 'Năm thứ hai' },
  { value: 3, label: 'Năm thứ ba' },
  { value: 4, label: 'Năm thứ tư' },
]

export const SCORE_LOCKS = [
  { value: 0, text: 'Mở khóa' },
  { value: 1, text: 'Khóa' },
]

export const PAYMENT_STATUSES = [
  { value: 0, label: 'Chưa đóng' },
  { value: 1, label: 'Đã đóng' },
]

export const GRADUATION_TYPE = [
  { value: 1, label: 'Làm đồ án, khóa luận tốt nghiệp' },
  { value: 3, label: 'Môn học chuyên môn thay thế' },
]
export const CLASS_NOTIFICATION_STATUSES = [
  { value: 0, label: 'Dự thảo' },
  { value: 1, label: 'Đã gửi' },
]
export const NOTIFICATION_STATUSES = [
  { value: 0, label: 'Dự thảo' },
  { value: 1, label: 'Đã gửi' },
]
export const ABSENT_TYPES = [
  // { value: -1, label: 'Hủy bỏ vắng mặt' },
  { value: 0, label: 'Không lý do' },
  { value: 1, label: 'Có lý do' },
]
export const EXAM_DEDUCT_TYPE = [
  { value: 1, label: 'Trừ vào tổng điểm' },
  { value: 2, label: 'Trừ phần trăm tổng điểm' },
]

export const CHECK_EXAMINATION_PAPERS = [
  { value: 1, label: 'Chờ phê duyệt' },
  { value: 2, label: 'Đã phê duyệt' },
  { value: 3, label: 'Đã hoàn thành' },
]
export const REPLACE_THESIS_SUBJECT_TYPES = [
  { value: 0, label: 'Không' },
  { value: 1, label: 'Có' },
]
export const SUSPEND_STATUSES = [
  { value: -1, label: 'Cảnh báo' },
  { value: 1, label: 'Thôi học' },
]

export const CATEGORY_LOGS = [
  { value: 'LOGIN', label: 'Đăng nhập hệ thống' },
  { value: 'UPDATE_RECEIPT', label: 'Cập nhật phiếu thu' },
  { value: 'CREATE_ACCOUNT_ROLES', label: 'Tạo tài khoản' },
  { value: 'CHANGE_ACCOUNT_ROLES', label: 'Thay đổi quyền tài khoản' },
  { value: 'RESET_PASSWORD', label: 'Đặt lại mật khẩu' },
  { value: 'CHANGE_PASSWORD', label: 'Thay đổi mật khẩu' },
  { value: 'DELETE_CREDIT_CLASS', label: 'Xóa lớp độc lập' },
  { value: 'EXAMINATION_SCORE_LOCKED', label: 'Khóa/mở nhập điểm thi theo môn học' },
  { value: 'CHANGE_STATUS_CREDIT_CLASS_STUDENT', label: 'Thay đổi trạng thái sinh viên trong lớp độc lập' },
  { value: 'DIPLOMA_REVIEWS', label: 'Đăng ký song ngành' },
  { value: 'UPDATE_SCORE_LOCKED', label: 'Khóa/mở nhập điểm theo lớp' },
  { value: 'REGISTER_SUBJECT', label: 'Đăng ký môn học' },
  { value: 'SUSPENDED_STUDENT', label: 'Buộc thôi học' },
  { value: 'CHANGE_INFO_STUDENT_CLASS', label: 'Thay đổi thông tin sinh viên' },
  { value: 'ENTER_POINT_EXAMINATION_ROOM', label: 'Nhập điểm thi' },
  { value: 'STUDY_SUMMARIES', label: 'Tổng kết học kỳ' },
  { value: 'ENTER_PRACTICE_SCORE', label: 'Điểm thực hành' },
  { value: 'ENTER_FIRST_POINT', label: 'Điểm hệ số 2' },
  { value: 'ENTER_SECOND_POINT', label: 'Điểm hệ số 3' },
  { value: 'CHECK_EXAMINATION', label: 'Phúc khảo' },
  { value: 'CONDUCT_POINT', label: 'Điểm rèn luyện' },
]

export const EXAMINATION_STATUS = [
  { value: 0, label: 'Mới mở' },
  { value: 1, label: 'Hoạt động' },
  { value: 2, label: 'Kết thúc' },
]

export const BACKUP_STATUS = [
  { value: 0, label: 'Thất bại' },
  { value: 1, label: 'Hoàn tất' },
]

export const RESEARCH_EVALUATIONS = [
  { value: 0, label: 'Không đạt' },
  { value: 1, label: 'Đạt' },
  { value: 2, label: 'Xuất sắc' },
]

export const GRANT_STATUS = [
  { value: 0, label: 'Chưa gán' },
  { value: 1, label: 'Đã gán' },
]

export const RESEARCH_INVENTION_STATUSES = [
  { value: 0, text: 'Chưa được chấp nhận' },
  { value: 1, text: 'Được chấp nhận' },
]

export const RESEARCH_STATUSES = [
  { value: 0, text: 'Đang nghiên cứu' },
  { value: 1, text: 'Đang xét duyệt' },
  { value: 2, text: 'Đã kết thúc' },
]

export const PHIEU_MUON_STATUSES = [
  { value: 1, text: 'Đang mượn' },
  { value: 2, text: 'Chưa giải quyết' },
  { value: 3, text: 'Xóa' },
  { value: 4, text: 'Từ chối' },
  { value: 5, text: 'Đồng ý cho mượn online nhưng chưa đến lấy' },
  { value: 6, text: 'Đang mượn' },
  { value: 7, text: 'Đã trả' },
  { value: 9, text: 'Quá hạn' },
]

export const CREDENTIAL_TYPE = [
  { value: 1, label: 'Văn bằng' },
  { value: 0, label: 'Chứng chỉ' },
]
export const STATUSES_CREDENTIAL = [
  { value: 0, label: 'Không hoạt động' },
  { value: 1, label: 'Hoạt động' },
  { value: 2, label: 'Đang triển khai' },
]
export const STATUSES_PROCESS_HISTORY = [
  { value: 2, label: 'Đã xử lý' },
  { value: 1, label: 'Đang xử lý' },
  { value: 0, label: 'Chờ xét duyệt' },
  { value: -1, label: 'Bị từ chối' },
]

export const STATUSES_GRADUATION_REGISTER = [
  { value: 1, label: 'Đủ điều kiện tốt nghiệp' },
  { value: 0, label: 'Chờ xét duyệt' },
  { value: -1, label: 'Bị từ chối' },
]

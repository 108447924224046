import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    years: [],
    graduationRegisterRound: null,
    graduationRegisterSubjectById: null,
    graduationRegisterSubjects: [],
    totalRows: 0,
  },
  getters: {
    years: state => state.years,
    graduationRegisterRound: state => state.graduationRegisterRound,
    graduationRegisterSubjects: state => state.graduationRegisterSubjects,
    graduationRegisterSubjectById: state => state.graduationRegisterSubjectById,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_YEARS: (state, data) => { state.years = data },
    SET_GRADUATION_REGISTER_ROUND_CURRENT: (state, data) => { state.graduationRegisterRound = data },
    SET_GRADUATION_REGISTER_SUBJECT_BY_ID: (state, data) => { state.graduationRegisterSubjectById = data },
    SET_GRADUATION_REGISTER_SUBJECTS: (state, { total, records }) => {
      state.totalRows = total
      state.graduationRegisterSubjects = records
    },
  },
  actions: {
    async getYears({ commit }, params) {
      try {
        const response = await axios.get('categories/factYears/all', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_YEARS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getGraduationRegisterRound({ commit }, params) {
      try {
        const response = await axios.get('uni/graduationRegisterRounds/getCurrent', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_GRADUATION_REGISTER_ROUND_CURRENT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async checkStudentGraduation({ commit }, studentClassId) {
      try {
        const response = await axios.post(`uni/graduations/checkStudentGraduation/${studentClassId}` )
        const { data } = response
        return { check: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
    async checkStudentHasQPAN({ commit }, studentClassId) {
      try {
        const response = await axios.post(`uni/graduations/checkStudentHasQPAN/${studentClassId}`)
        const { data } = response
        return { check: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createGraduationRegisterStudent({ commit }, params) {
      try {
        const response = await axios.post('uni/graduationRegisterStudents', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async checkGraduationStudent({ commit }, params) {
      try {
        const response = await axios.post(`uni/graduationRegisterStudents/checkGraduationStudent`, params)
        const { data } = response
        return { check: data.data }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getGraduationRegisterSubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/graduationRegisterStudents', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_GRADUATION_REGISTER_SUBJECTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getGraduationRegisterSubjectById({ commit }, params) {
      try {
        const response = await axios.get('uni/graduationRegisterStudents/getByStudentClassId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_GRADUATION_REGISTER_SUBJECT_BY_ID', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateSubjectGraduation({ commit }, params) {
      try {
        const response = await axios.put('uni/graduationRegisterStudents/updateSubjectGraduation', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateScoreGraduation({ commit }, params) {
      try {
        const response = await axios.put('uni/graduationRegisterStudents/updateScoreGraduation', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}

import { ResourceCode } from '@/const/code';

export default [
  {
    path: '/registerSubject',
    name: 'registerSubject',
    component: () => import('@/views/register-subject/RegisterSubject.vue'),
    meta: {
      resource: ResourceCode.PORTAL_REGISTER_SUBJECT,
    },
  },
  {
    path: '/registerGraduationProject',
    name: 'registerGraduationProject',
    component: () => import('@/views/register-graduation-project/RegisterGraduationProject.vue'),
    meta: {
      resource: ResourceCode.PORTAL_REGISTER_GRADUATION_PROJECT,
    },
  },
  {
    path: '/registerGraduation',
    name: 'registerGraduation',
    component: () => import('@/views/register-graduation/RegisterGraduation.vue'),
    meta: {
      resource: ResourceCode.PORTAL_REGISTER_GRADUATION,
    },
  },
]
